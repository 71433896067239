import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-tabs" }
const _hoisted_2 = { class: "app-tabs__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTabsHead = _resolveComponent("AppTabsHead")!
  const _component_AppTabsBody = _resolveComponent("AppTabsBody")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppTabsHead, {
      modelValue: _ctx.selectedTabIdx,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTabIdx) = $event)),
      margin: _ctx.isMargin,
      tabs: _ctx.tabsHead
    }, null, 8, ["modelValue", "margin", "tabs"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppTabsBody, {
        "selected-tab-idx": _ctx.modelValue,
        animate: _ctx.animate
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "tabs-content-list")
        ]),
        _: 3
      }, 8, ["selected-tab-idx", "animate"])
    ])
  ]))
}