import { IApiResponse } from "@/api/interfaces/IApi";
import { Store } from "vuex";

interface editEsimPlanStatusDto {
  id: number;
  value: string;
  status: boolean;
}
export async function updatePlanStatus(
  options: editEsimPlanStatusDto,
  store: Store<any>
): Promise<IApiResponse> {
  const { id, status, value } = options;
  const res = await store.dispatch("updatePlanStatus", {
    id,
    status,
    value
  });

  const { success, message } = res;

  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }

  return res;
}
