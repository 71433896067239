import { IApiResponse } from "@/api/interfaces/IApi";
import { IZone } from "@/api/services/packages/esim/location-packages/types";
import { Store } from "vuex";

interface editEsimPlanEsimZoneDto {
  id: number;
  zone: IZone;
}
export async function updatePlanEsimZone(
  options: editEsimPlanEsimZoneDto,
  store: Store<any>
): Promise<IApiResponse> {
  const { id, zone } = options;
  const { success, message } = await store.dispatch("changePlanEsimZone", {
    id,
    zone
  });

  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }

  return success;
}
