import { TLocationName } from "@/hooks/esim/location-packages/modules/locationKeys";
import { IEsimPLans } from "@/api/interfaces/esim-plans/common";
import {
  IEsimPlansBodyListItem,
  IEsimPlansListItem
} from "../types/useEsimPlansListType";

export function useEsimPlansList(
  plans: IEsimPLans[] = []
): IEsimPlansListItem[] {
  return plans.map(
    ({ name, id, countryPlans, subregionPlans, regionPlans }) => {
      const currentPlans = countryPlans || subregionPlans || regionPlans || [];
      const canExpended = true;
      let currentLocationName: TLocationName;

      if (countryPlans) {
        currentLocationName = "country";
      } else if (regionPlans) {
        currentLocationName = "region";
      } else {
        currentLocationName = "subregion";
      }

      if (!canExpended) {
        return {
          id,
          name,
          canExpended,
          plans: [],
          currentLocationName
        };
      }

      return {
        id,
        canExpended: true,
        currentLocationName,
        name,
        plans: currentPlans.map(
          ({
            id,
            plan,
            zone,
            discount,
            discountAmount,
            planOperators = [],
            esimType,
            esimServer,
            active
          }): IEsimPlansBodyListItem => {
            const { name: planName } = plan;
            return {
              name: planName,
              discount,
              discountAmount,
              id,
              active,
              zone,
              planOperators,
              esimServer,
              esimType
            };
          }
        )
      };
    }
  );
}
