import { ITableBodyRow } from "@/components/Table/ts/interfaces/TableStructure";
import { dateConverter } from "@/utills/dateConverter";
import { infoWithImage } from "@/components/Table/ts/interfaces/common";
import { IEsimPLans } from "@/api/interfaces/esim-plans/common";

export const generateEsimPlansRows = () => {
  return function(plansList: IEsimPLans[] = []): ITableBodyRow[] {
    return plansList.map(($package: IEsimPLans) => {
      const {
        name,
        icon = "",
        subregions,
        regionPlans,
        countryPlans,
        subregionPlans,
        updatedAt,
        updatedBy
      } = $package;

      const locationPlans = regionPlans || countryPlans || subregionPlans || [];
      const countActivePlans = locationPlans.filter(plan => plan.active).length;
      const updatedDate: Date = new Date(updatedAt);

      const updatedByCell: {
        infoWithImage?: infoWithImage;
        label?: string;
      } = {};

      if (updatedBy?.fullName) {
        const { fullName, image } = updatedBy;

        updatedByCell.infoWithImage = {
          title: fullName,
          image
        };
      } else {
        updatedByCell.label = "-";
      }
      let cells;
      if (subregions) {
        cells = [
          { country: { icon, name } },
          { label: `${subregions.length} subregions` },
          { label: `${countActivePlans} plans` },
          { label: dateConverter(updatedDate) },
          { ...updatedByCell }
        ];
      } else {
        cells = [
          { country: { icon, name } },
          { label: `${countActivePlans} plans` },
          { label: dateConverter(updatedDate) },
          { ...updatedByCell }
        ];
      }
      return { cells };
    });
  };
};
