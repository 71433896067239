export async function getEsimPlans(options: any, store: any) {
  const { page, perPage, search, filter, value } = options;
  await store.dispatch("setAppLoading", true);

  const res = await store.dispatch("fetchEsimPlans", {
    query: { page, perPage, search },
    filter,
    value
  });

  const { success, message } = res;

  if (!success && message) {
    await store.dispatch("showErrorNotification", message);
  }

  await store.dispatch("setAppLoading", false);

  return res;
}
