import { IApiResponse } from "@/api/interfaces/IApi";
import { IZone } from "@/api/services/packages/esim/location-packages/types";
import { sortOrders } from "@/enums/main/sortOrders";
import { ITab } from "@/store/interfaces/common";
import { computed, ComputedRef, reactive, ref, Ref, watch } from "vue";
import { useStore } from "vuex";
import { locationKeys } from "../esim/location-packages/modules/locationKeys";
import {
  locationPackagesFilterOptions,
  TPackageLocation
} from "../esim/location-packages/types/locationPackage.types";
import { useBaseAppLayout } from "../layout/useBaseAppLayout";
import { useBaseTable } from "../tables/useTable";
import { esimPlansTabs } from "./instanse/esimPlansTabs";
import { generateEsimPlansHead } from "./modules/generateEsimPlanHead";
import { generateEsimPlansRows } from "./modules/generateEsimPlansRows";
import { getEsimPlans } from "./modules/getEsimPlans";
import { getEsimPlansSortConfig } from "./modules/getEsimPlansSortConfig";
import {
  editEsimPlanEsimServerDto,
  updatePlanEsimServer
} from "./modules/updatePlanEsimServer";
import { updatePlanEsimZone } from "./modules/updatePlanEsimZone";
import { updatePlanOperatorStatus } from "./modules/updatePlanOperatorStatus";
import { updatePlanStatus } from "./modules/updatePlanStatus";
import { useEsimPlansList } from "./modules/useEsimPlansList";
import {
  IEsimPlansListItem,
  UpdatePlanDiscountDto
} from "./types/useEsimPlansListType";
import { updatePlanDiscount } from "@/hooks/esim-plans/modules/updatePlanDiscount";

export function useEsimPlans(locationType: Ref<TPackageLocation>) {
  const store = useStore();
  const esimPlansModel = computed(() => {
    return store.getters.esimPlansList(locationType.value);
  });

  const totalRowsCount = computed(() => {
    return store.getters.esimPlansTotal(locationType.value);
  });

  const currentSortKeyLocationName = computed(() => {
    if (!locationType.value) {
      return "";
    }

    return locationKeys[locationType.value].single;
  });

  const initialSortOptions = getEsimPlansSortConfig(currentSortKeyLocationName);
  const {
    filter,
    searchQuery,
    selectedFilterOption,
    onFilterSelect,
    searchHandler
  } = useBaseAppLayout({
    initialSearchQuery: "",
    filterOptions: [locationPackagesFilterOptions.all, "emptyonly"]
  });

  const currentViewConfiguration = reactive({ page: 1, perPage: 10 });
  const selectedTab: Ref<ITab> = ref({ ...esimPlansTabs[0] });
  const requestOptions = reactive({
    ...currentViewConfiguration,
    search: searchQuery.value,
    value: selectedTab.value,
    filter:
      selectedFilterOption.value?.name || locationPackagesFilterOptions.all
  });
  const initialViewConfiguration = { page: 1, perPage: 10 };
  const localeCurrentSort = ref({
    keyName: initialSortOptions.byLocationName.keyName,
    order: initialSortOptions.byLocationName.order
  });
  const generateTableRows = generateEsimPlansRows();
  const {
    viewConfiguration,
    rows,
    currentSort,
    changeViewConfiguration,
    changeSorting,
    sortedModel
  } = useBaseTable({
    initialSortOptions: initialSortOptions,
    initialViewConfiguration,
    model: esimPlansModel,
    generateRows: generateTableRows,
    onViewConfigurationChanged: ({ page, perPage }) => {
      if (requestOptions.perPage !== perPage) {
        requestOptions.page = 1;
      } else {
        requestOptions.page = page;
      }

      requestOptions.perPage = perPage;
    }
  });

  const esimPlansList: ComputedRef<IEsimPlansListItem[]> = computed(() => {
    return useEsimPlansList(sortedModel.value);
  });

  async function fetchPlans(): Promise<IApiResponse> {
    return await getEsimPlans(requestOptions, store);
  }

  watch(
    currentSort,
    sort => {
      const { keyName = "", order = sortOrders.turnOff } =
        Object.values(sort).find(({ order }) => order !== sortOrders.turnOff) ||
        {};

      localeCurrentSort.value = { keyName, order };
    },
    { immediate: true, deep: true }
  );

  watch(searchQuery, query => {
    requestOptions.search = query;
    changeViewConfiguration({
      page: 1,
      perPage: currentViewConfiguration.perPage
    });
    fetchPlans();
  });

  watch(selectedFilterOption, selectedFilterOption => {
    changeViewConfiguration({
      page: 1,
      perPage: currentViewConfiguration.perPage
    });

    requestOptions.filter =
      selectedFilterOption?.name || locationPackagesFilterOptions.all;
    fetchPlans();
  });

  watch(
    viewConfiguration,
    async () => {
      if (requestOptions.perPage !== viewConfiguration.perPage) {
        requestOptions.page = 1;
        changeViewConfiguration({
          page: 1,
          perPage: viewConfiguration.perPage
        });
      } else {
        requestOptions.page = viewConfiguration.page;
      }
      requestOptions.perPage = viewConfiguration.perPage;
      await fetchPlans();
    },
    { deep: true, immediate: true }
  );

  watch(
    locationType,
    async () => {
      changeViewConfiguration({ page: 1, perPage: 10 });
      requestOptions.value = selectedTab.value;
      if (!esimPlansModel.value.length) {
        await fetchPlans();
      }
    },
    { deep: true }
  );

  const pageTitle: ComputedRef<string> = computed(() => {
    if (!locationType.value) {
      return "";
    }

    const keyName = locationKeys[locationType.value].single;
    return `${keyName} Packages`;
  });

  const changeStatusHandler = async (id: number, status: boolean) => {
    updatePlanStatus(
      {
        id,
        status,
        value:
          typeof selectedTab.value.value === "string"
            ? selectedTab.value.value
            : ""
      },
      store
    );
  };

  const changeOperatorStatusHandler = async (
    id: number,
    status: boolean,
    planId: number
  ) => {
    updatePlanOperatorStatus(
      {
        id,
        status,
        value: locationKeys[locationType.value].single || "",
        planId,
        locationsKey: locationKeys[locationType.value].multiple
      },
      store
    );
  };
  const changeEsimZoneHandler = async (id: number, zone: IZone) => {
    updatePlanEsimZone({ id, zone }, store);
  };

  const changeEsimServerHandler = async (data: editEsimPlanEsimServerDto) => {
    updatePlanEsimServer(data, locationKeys[locationType.value].single, store);
  };

  const changeDiscountHandler = async (data: UpdatePlanDiscountDto) => {
    const { success, message } = await updatePlanDiscount({
      ...data,
      type: locationKeys[locationType.value].single
    });
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }
  };
  const tableHead = computed(() =>
    generateEsimPlansHead(
      locationType.value,
      currentSort,
      currentSortKeyLocationName.value
    )
  );

  const tabs = ref(esimPlansTabs.map(el => el.title));
  return {
    tableHead,
    rows,
    totalRowsCount,
    filter,
    currentSort,
    searchQuery,
    sortedModel,
    selectedFilterOption,
    viewConfiguration,
    pageTitle,
    initialSortOptions,
    currentSortKeyLocationName,
    esimPlansList,
    selectedTab,
    tabs,
    fetchPlans,
    onFilterSelect,
    searchHandler,
    changeViewConfiguration,
    changeSorting,
    changeTabHandler(idx: number) {
      selectedTab.value = <ITab>esimPlansTabs[idx];
      locationType.value = <TPackageLocation>esimPlansTabs[idx].locationType;
    },
    changeStatusHandler,
    changeDiscountHandler,
    changeOperatorStatusHandler,
    changeEsimZoneHandler,
    changeEsimServerHandler
  };
}
